export default {
    "material.game.name" : "游戏名称",
    "material.country.code" : "国家代码",
    "material.country.name" : "国家名称",
    "material.createTime" : "创建时间",
    "material.updateTime" : "修改时间",
    "material.beginTime" : "开始时间",
    "material.finishTime" : "结束时间",
    "material.creative.name" : "创意名称",

    "material.queue.table.title" : "渲染队列",
    "material.game.table.title" : "游戏模块",
    "material.creative.table.title" : "创意列表",
    "material.table.title" : "素材列表",

    "material.queue.table.created": "提交",
    "material.queue.table.running": "渲染中",
    "material.queue.table.success": "渲染成功",
    "material.queue.table.failed": "渲染失败",

    "material.platform.name" : "平台",
    "material.comp.name" : "规格",
    "material.file.name" : "文件名",
    "material.file.view" : "查看文件",
}