import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  title: 'RIVEROLL',
  pwa: false,
  logo: 'https://webcontentbucket.oss-cn-shenzhen.aliyuncs.com/workbench/images/cropped-website_icon-32x32.png',
  iconfontUrl: '',
};

export default Settings;
