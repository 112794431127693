export const youtube = {
  "tool.name": '素材名称',
  "tool.game.name": '游戏名称',
  "tool.country.code" : "国家代码",
  "tool.country.name" : "国家名称",
  "tool.video.id": "视频ID",
  "tool.video.name": "视频名称",
  "tool.video.playUrl": "视频播放地址",
  "tool.platform.name": "平台",
  "tool.description": "说明",
  "tool.creative.name": "创意名称",
  "tool.comp.name": "规格",
  "tool.uploadDate": "更新时间",
  
}
export default {
  ...youtube
}