/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */

import ReactDOM from 'react-dom';
import { RequestMethod, RequestOptionsInit, ResponseError, extend } from 'umi-request';
import { history } from 'umi';
import { notification, Spin, message } from 'antd';
import 'yet-another-abortcontroller-polyfill';

export type TableRequestSuccessResponse = {
  code?: string | number;
  data?: any;
  errorCode?: string;
  msg?: string;
  current?: number;
  pageSize?: number;
  total?: number;
};

export type CommonResponseData =
  | TableRequestSuccessResponse
  | {
      code: number | string;
      data: any;
      errorCode?: string;
      msg?: string;
    }
  | {
      error: string;
      path: string;
      status: number;
      timestamp: string;
    };

// http://192.168.5.232:9001 ｜ http://localhost:9001 | https://test-workbench.riveroll.top/ | http://192.168.2.184:9001
// const baseUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://192.168.2.12:9001'
//     : window.location.protocol + '//' + window.location.host;
const baseUrl =
  process.env.NODE_ENV === 'development' ? 'https://test-workbench.riveroll.top/' : process.env.WB_SERVER_HOST;
  console.warn('域名', process.env.WB_SERVER_HOST);

// let queue = {}
/** 请求集合 */
let queue = new Map<string, AbortController>();

const addQueue = (options: RequestOptionsInit, controller: AbortController) => {
  const key = getQueueKey(options);
  queue.set(key, controller);
  // console.log('add queue', [...queue.keys()]);
  // console.log('请求队列',queue)
};
const removeQueue = (url: string) => {
  url = decodeURIComponent(url);
  if (queue.has(url)) {
    queue.delete(url);
  }
  if (queue.size === 0) hideLoading();
  // console.log('remove queue', [...queue.keys()]);
};

export const showLoading = () => {
  const winLoading = document.getElementById('winLoading');
  if (winLoading) {
    console.log('show loading');
    winLoading.style.visibility = 'visible';
    return;
  }
  console.log('create and show loading');
  var dom = document.createElement('div');
  dom.setAttribute('id', 'winLoading');
  document.body.appendChild(dom);
  ReactDOM.render(<Spin />, dom);
};

export const hideLoading = () => {
  // @ts-ignore
  let node = document.getElementById('winLoading');
  // if (node) document.body.removeChild(node);
  if (node) {
    // console.log('hide loading');
    node.style.visibility = 'hidden';
  }
};

// type RequestResponseStatus = 'success' | '' | 'error'
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const retryTimes = 3;
const retryMap = new Map<string, number>();
/** 重试请求 */
const retryRequest = async (options: RequestOptionsInit, errorDesc: string, delay?: number) => {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay ?? 1000);
  });
  const url = options.url;
  const key = getQueueKey(options);
  if (!retryMap.has(key)) {
    console.log('重试请求次数', 1);
    retryMap.set(key, 1);
    return request(url, options);
  } else {
    let count = retryMap.get(key) ?? 1;
    if (count < retryTimes) {
      retryMap.set(key, count + 1);
      console.log('重试请求次数', count + 1);
      // 尝试重新请求
      return request(url, options);
    } else {
      console.log('超出重试请求次数');
      removeQueue(key);
      // 超出重试次数
      retryMap.delete(key);
      notification.error({
        message: `错误请求: ${url}`,
        description: errorDesc,
      });
      return false;
    }
  }
};

/**
 * 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
  const { response } = error;
  console.warn('异常请求处理');
  const key = getQueueKey(error.request.options);
  if (
    error.type &&
    error.type.toLowerCase() === 'timeout' &&
    error.request.options.method === 'GET'
  ) {
    // console.log(error.request);
    const options = error.request.options;
    queue.get(key)?.abort();
    removeQueue(key);
    notification.info({
      description: '重试中',
      message: '网络异常',
    });
    return retryRequest(options, codeMessage[response?.status] ?? response?.statusText ?? '');
  }
  hideLoading();
  const errorText = response ? codeMessage[response.status] || response.statusText : '网络异常';
  if (response && response.status) {
    const { status, url } = response;

    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  throw new Error(errorText);
};

/**
 * 配置request请求时的默认参数
 */
const request: RequestMethod<false> = extend({
  method: 'GET',
  timeout: 50 * 1000,
  prefix: baseUrl,
  errorHandler,
});

const transformRequest = (data: any) => {
  let params_str = '';
  if (typeof data === 'object') {
    for (var i in data) {
      if (data[i] instanceof Array) {
        for (var j in data[i]) {
          params_str +=
            i +
            '=' +
            (typeof data[i][j] === 'object'
              ? encodeURIComponent(JSON.stringify(data[i][j]))
              : encodeURIComponent(data[i][j])) +
            '&';
        }
      } else {
        if (!(String(data[i]) === 'null' || String(data[i]) === 'undefined')) {
          params_str +=
            i +
            '=' +
            (typeof data[i] === 'object'
              ? encodeURIComponent(JSON.stringify(data[i]))
              : encodeURIComponent(data[i])) +
            '&';
        }
        // else {
        //   params_str += i + '=&';
        // }
      }
    }
  }
  return params_str;
};

const getQueueKey = (options: RequestOptionsInit) => {
  const url = baseUrl + options.url;
  let queueKey = url;
  let getParamsStr = transformRequest(options.params);
  if (getParamsStr.length) {
    // 去掉最后的 & 符号
    getParamsStr = getParamsStr.substring(0, getParamsStr.length - 1);
  }
  if (getParamsStr) {
    queueKey = `${url.indexOf('?') !== -1 ? url : url + '?'}${getParamsStr}`;
  }
  return decodeURIComponent(queueKey);
};

// 请求拦截
request.interceptors.request.use((url, options) => {
  let token = localStorage.getItem('token');
  let hideLoading = false;

  // @ts-ignore
  if (options.params && Object.keys(options.params).length && options.params.noSpin) {
    hideLoading = true;
    // @ts-ignore
    delete options.params.noSpin;
  }

  // if (queue.size === 0 && !hideLoading) showLoading();
  const controller = new AbortController();
  const { signal } = controller;
  addQueue(options, controller);
  if (!hideLoading) {
    // 添加全局的loading...
    showLoading();
  }

  return {
    url,
    options: {
      ...options,
      interceptors: true,
      headers: {
        token: token || '',
      },
      signal,
    },
  };
});
// { global: false}
function go2Login() {
  let path = location.pathname;
  if (path.endsWith('/')) {
    path = path.slice(0, path.length - 1);
  }
  if (path !== '/user/login') {
    history.replace(`/user/login?redirect=${path}`);
  }
}
// 响应拦截
// @ts-ignore
request.interceptors.response.use(async (response: Response, options: RequestOptionsInit) => {
  const { url } = response;
  removeQueue(url);
  const ResponseData = await response.clone().json();
  const responseCode = ResponseData.code;
  if (response.status === 200) {
    switch (responseCode) {
      case '401': {
        go2Login();
        // location.reload();
        console.log('401 请先登录');
        return false;
      }
      case '300': {
        go2Login();
        console.log('300 请先登录');
        return false;
      }
      case '402': {
        message.error('无权限');
        console.log('402 无权限');
        return ResponseData;
      }
      case '504':
      case '500': {
        const msg = ResponseData.msg || '系统错误,请联系管理员';
        notification.error({
          message: msg,
        });
        return false;
      }
      case '505': {
        retryRequest(options, ResponseData.msg ?? '');
        return false;
      }
      default: {
        return ResponseData;
      }
    }
  } else {
    notification.error({
      message: ResponseData.error || '请求错误',
    });
    return ResponseData;
  }
});

request.use(async (ctx, next) => {
  await next();
});

export default request;
