// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/riveroll-workbench/riveroll-workbench/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/",
        "routes": [
          {
            "path": "/welcome",
            "name": "welcome",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/Welcome'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user",
            "layout": false,
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/bi",
            "name": "pointer",
            "icon": "areaChart",
            "routes": [
              {
                "path": "/bi/search",
                "name": "search",
                "extract": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bi__finebi' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/bi/finebi'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/bi/search/:report",
                "name": "board",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bi__finebi' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/bi/finebi'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/bi/inquire/:report",
                "name": "inquire",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bi__finebi' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/bi/finebi'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/material",
            "name": "material",
            "icon": "crown",
            "access": "normalRouteFilter",
            "routes": [
              {
                "path": "/material/game-module-list",
                "name": "game-module-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__game-module-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/game-module-list'), loading: LoadingComponent}),
                "access": "normalRouteFilter",
                "exact": true
              },
              {
                "path": "/material/creative-list",
                "name": "creative-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__creative-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/creative-list'), loading: LoadingComponent}),
                "access": "normalRouteFilter",
                "exact": true
              },
              {
                "path": "/material/material-render-queue-list",
                "name": "material-render-queue-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-render-queue-List' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-render-queue-List'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/material/material-render-queue-list/material-render-queue-details/:id",
                "name": "material-render-queue-details",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-render-queue-List__details' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-render-queue-List/details'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/material/material-push-queue-list",
                "name": "material-push-queue-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-push-queue-List' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-push-queue-List'), loading: LoadingComponent}),
                "access": "normalRouteFilter",
                "exact": true
              },
              {
                "path": "/material/material-list",
                "name": "material-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-List' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-List'), loading: LoadingComponent}),
                "access": "normalRouteFilter",
                "exact": true
              },
              {
                "path": "/material/material-localize",
                "name": "material-localize",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-localize' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-localize'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/material/material-localize/BuildAudio",
                "name": "BuildAudio",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material__material-localize__BuildAudio' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/material/material-localize/BuildAudio'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/promotion",
            "name": "promotion",
            "icon": "rocket",
            "routes": [
              {
                "path": "/promotion/intelligent-monitoring",
                "name": "intelligent-monitoring",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__intelligent-monitoring' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/intelligent-monitoring'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/intelligent-monitoring/diagnosis-detail/:id",
                "name": "diagnosis-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__intelligent-monitoring__diagnosis-detail' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/intelligent-monitoring/diagnosis-detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/intelligent-monitoring/adjust-track",
                "name": "intelligent-monitoring.adjust-track",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__intelligent-monitoring__AdjustTrack' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/intelligent-monitoring/AdjustTrack'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/intelligent-monitoring/diagnosis-strategy",
                "name": "intelligent-monitoring.diagnosis-strategy",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__intelligent-monitoring__DiagnosisStrategy' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/intelligent-monitoring/DiagnosisStrategy'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/intelligent-monitoring/diagnosis-strategy/:editType",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__intelligent-monitoring__DiagnosisStrategy__Edit' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/intelligent-monitoring/DiagnosisStrategy/Edit'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/promotion/intelligent-monitoring/diagnosis-strategy/create",
                    "name": "intelligent-monitoring.diagnosis-strategy.create",
                    "exact": true
                  },
                  {
                    "path": "/promotion/intelligent-monitoring/diagnosis-strategy/edit",
                    "name": "intelligent-monitoring.diagnosis-strategy.edit",
                    "exact": true
                  },
                  {
                    "path": "/promotion/intelligent-monitoring/diagnosis-strategy/view",
                    "name": "intelligent-monitoring.diagnosis-strategy.view",
                    "exact": true
                  }
                ]
              },
              {
                "path": "/promotion/guanggao-list",
                "name": "guanggao-list",
                "exact": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list'), loading: LoadingComponent}),
                "redirect": "/promotion/guanggao-list/mintegral/app"
              },
              {
                "path": "/promotion/guanggao-list/:platform",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list'), loading: LoadingComponent}),
                "name": "guanggao-list",
                "exact": true
              },
              {
                "path": "/promotion/guanggao-list/:platform/:pageName",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list'), loading: LoadingComponent}),
                "name": "guanggao-list",
                "routes": [
                  {
                    "path": "/promotion/guanggao-list/unity",
                    "name": "unity",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__unity' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/unity'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/promotion/guanggao-list/unity/:pageName",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__unity' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/unity'), loading: LoadingComponent}),
                        "name": "unity-list",
                        "routes": [
                          {
                            "path": "/promotion/guanggao-list/unity/game",
                            "name": "unity-game",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__unity__game' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/unity/game'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/unity/campaign",
                            "name": "unity-campaign",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__unity__campaign' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/unity/campaign'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/unity/creativePacks",
                            "name": "unity-creativePacks",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__unity__creativePacks' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/unity/creativePacks'), loading: LoadingComponent}),
                            "exact": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "path": "/promotion/guanggao-list/mintegral",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__mintegral' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/mintegral'), loading: LoadingComponent}),
                    "name": "mintegral",
                    "routes": [
                      {
                        "path": "/promotion/guanggao-list/mintegral/:pageName",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__mintegral' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/mintegral'), loading: LoadingComponent}),
                        "name": "mintegral-list",
                        "routes": [
                          {
                            "path": "/promotion/guanggao-list/mintegral/app",
                            "name": "mintegral-app",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__mintegral__app' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/mintegral/app'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/mintegral/campaign",
                            "name": "mintegral-campaign",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__mintegral__campaign' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/mintegral/campaign'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/mintegral/creative",
                            "name": "mintegral-creative",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__mintegral__creative' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/mintegral/creative'), loading: LoadingComponent}),
                            "exact": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "path": "/promotion/guanggao-list/applovin",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__applovin' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/applovin'), loading: LoadingComponent}),
                    "name": "applovin",
                    "routes": [
                      {
                        "path": "/promotion/guanggao-list/applovin/:pageName",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__applovin' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/applovin'), loading: LoadingComponent}),
                        "name": "applovin-list",
                        "routes": [
                          {
                            "path": "/promotion/guanggao-list/applovin/app",
                            "name": "applovin-app",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__applovin__app' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/applovin/app'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/applovin/campaign",
                            "name": "applovin-campaign",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__applovin__campaign' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/applovin/campaign'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/promotion/guanggao-list/applovin/creativePacks",
                            "name": "applovin-creativePacks",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__applovin__creativePacks' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/applovin/creativePacks'), loading: LoadingComponent}),
                            "exact": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "path": "/promotion/guanggao-list/tiktok/:pageName",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__guanggao-list__tiktok' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/guanggao-list/tiktok'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/promotion/batch-promotion-list",
                "name": "batch-promotion-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list'), loading: LoadingComponent}),
                "redirect": "/promotion/batch-promotion-list/unity",
                "exact": true
              },
              {
                "path": "/promotion/batch-promotion-list/:platform",
                "name": "batch-promotion-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/promotion/batch-promotion-list/unity",
                    "name": "unity",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list__unity' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list/unity'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/promotion/batch-promotion-list/mintegral",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list__mintegral' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list/mintegral'), loading: LoadingComponent}),
                    "name": "mintegral",
                    "exact": true
                  },
                  {
                    "path": "/promotion/batch-promotion-list/applovin",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list__applovin' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list/applovin'), loading: LoadingComponent}),
                    "name": "applovin",
                    "exact": true
                  },
                  {
                    "path": "/promotion/batch-promotion-list/tiktok",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-list__tiktok' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-list/tiktok'), loading: LoadingComponent}),
                    "name": "tiktok",
                    "exact": true
                  }
                ]
              },
              {
                "path": "/promotion/batch-promotion-add",
                "name": "batch-promotion-add",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-add' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-add'), loading: LoadingComponent}),
                "redirect": "/promotion/batch-promotion-add/unity",
                "exact": true
              },
              {
                "path": "/promotion/batch-promotion-add/:platform",
                "name": "batch-promotion-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-add' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-add'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/batch-promotion-draft",
                "name": "batch-promotion-draft",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-draft' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-draft'), loading: LoadingComponent}),
                "redirect": "/promotion/batch-promotion-draft/unity",
                "exact": true
              },
              {
                "path": "/promotion/batch-promotion-draft/:platform",
                "name": "batch-promotion-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__batch-promotion-draft' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/batch-promotion-draft'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/promotion/ad-task-queue",
                "name": "ad-task-queue",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotion__ad-task-queue' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/promotion/ad-task-queue'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/realization",
            "name": "realization",
            "icon": "tool",
            "access": "normalRouteFilter",
            "routes": [
              {
                "path": "/realization/adunits",
                "name": "adunits",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__realization__adunits' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/realization/adunits'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/game-ops",
            "name": "game-ops",
            "icon": "build",
            "access": "normalRouteFilter",
            "routes": [
              {
                "name": "user-level",
                "path": "/game-ops/user-level",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__user-level' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/user-level'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/game-ops/user-level/:editType",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__user-level__edit' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/user-level/edit'), loading: LoadingComponent}),
                "routes": [
                  {
                    "name": "user-level-edit",
                    "path": "/game-ops/user-level/edit",
                    "exact": true
                  },
                  {
                    "name": "user-level-view",
                    "path": "/game-ops/user-level/view",
                    "exact": true
                  }
                ]
              },
              {
                "name": "point-control",
                "path": "/game-ops/point-control",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__point-control' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/point-control'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "aimirror",
                "path": "/game-ops/aimirror",
                "routes": [
                  {
                    "name": "draw-history",
                    "path": "/game-ops/aimirror/draw-history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__draw-history' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/draw-history'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "render-history",
                    "path": "/game-ops/aimirror/render-history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__render-history' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/render-history'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "home-page-config",
                    "path": "/game-ops/aimirror/home-page-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__home-page-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/home-page-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "model-config",
                    "path": "/game-ops/aimirror/model-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__model-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/model-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "model-config-edit",
                    "path": "/game-ops/aimirror/model-config/edit/:id?",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__model-config__Edit' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/model-config/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "home-group-config",
                    "path": "/game-ops/aimirror/home-group-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__home-group-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/home-group-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "loading-tips-config",
                    "path": "/game-ops/aimirror/loading-tips-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__loading-tips-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/loading-tips-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "decoration-config",
                    "path": "/game-ops/aimirror/decoration-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__decoration-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/decoration-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "wish-list",
                    "path": "/game-ops/aimirror/wish-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__wish-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/wish-list'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "hot-word-search",
                    "path": "/game-ops/aimirror/hot-word-search",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__hot-word-search' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/hot-word-search'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "monitor-pane",
                    "path": "/game-ops/aimirror/monitor-pane",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__aimirror__monitor-pane' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/aimirror/monitor-pane'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "spellai",
                "path": "/game-ops/spellai",
                "routes": [
                  {
                    "name": "draw-history",
                    "path": "/game-ops/spellai/draw-history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__draw-history' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/draw-history'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "draw-history-new",
                    "path": "/game-ops/spellai/draw-history-new",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__draw-history-new' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/draw-history-new'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "model-config",
                    "path": "/game-ops/spellai/model-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__model-config' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/model-config'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "feeds-list",
                    "path": "/game-ops/spellai/feeds-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__feeds-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/feeds-list'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "feeds-search",
                    "path": "/game-ops/spellai/feeds-search",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__feeds-search' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/feeds-search'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "hot-word-stat",
                    "path": "/game-ops/spellai/hot-word-stat",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__hot-word-stat' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/hot-word-stat'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "nsfw-setting",
                    "path": "/game-ops/spellai/nsfw-setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__nsfw-setting' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/nsfw-setting'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "monitor-pane",
                    "path": "/game-ops/spellai/monitor-pane",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__spellai__monitor-pane' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/spellai/monitor-pane'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "mova",
                "path": "/game-ops/mova",
                "routes": [
                  {
                    "name": "render-history",
                    "path": "/game-ops/mova/mova-history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__mova__mova-history' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/mova/mova-history'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "monitor-pane",
                    "path": "/game-ops/mova/monitor-pane",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__mova__monitor-pane' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/mova/monitor-pane'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "name": "bestshot",
                "path": "/game-ops/bestshot",
                "routes": [
                  {
                    "name": "render-history",
                    "path": "/game-ops/bestshot/draw-history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__game-ops__bestshot__draw-history' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/game-ops/bestshot/draw-history'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/tool",
            "name": "tool",
            "icon": "tool",
            "routes": [
              {
                "path": "/tool/youtube",
                "name": "youtube",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tool__youtube' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/tool/youtube'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/tool/youtube/add",
                "name": "youtube-add",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tool__youtube__Edit' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/tool/youtube/Edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/tool/useful-toll",
                "name": "useful-toll",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tool__useful-toll' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/tool/useful-toll'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/admin",
            "name": "admin",
            "icon": "setting",
            "routes": [
              {
                "path": "/admin/user-manager",
                "name": "user-manager",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__user-manager' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/admin/user-manager'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "point",
                "path": "/admin/user-manager/point",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__emp-career__point' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/account/emp-career/point'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/admin/role-manager",
                "name": "role-manager",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__role-manager' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/admin/role-manager'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/admin/permission-manager",
                "name": "permission-manager",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__permission-manager' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/admin/permission-manager'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/admin/business-log",
                "name": "business-log",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__business-log' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/admin/business-log'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/asserts",
            "name": "asserts",
            "icon": "accountBook",
            "access": "normalRouteFilter",
            "routes": [
              {
                "path": "/asserts/platform-account",
                "name": "platform-account",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asserts__platform-account' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/asserts/platform-account'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/asserts/ecs",
                "name": "ecs",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asserts__ecs' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/asserts/ecs'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/asserts/game-board",
                "name": "game-board",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asserts__game-board' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/asserts/game-board'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/asserts/game-board/sdk-build",
                "name": "game-board.sdk-build",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asserts__game-board__sdk-build' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/asserts/game-board/sdk-build'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "account",
            "icon": "user",
            "path": "/account",
            "access": "normalRouteFilter",
            "routes": [
              {
                "name": "settings",
                "path": "/account/settings",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__settings' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/account/settings'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "emp-career",
                "path": "/account/emp-career",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__emp-career' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/account/emp-career'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "point",
                "path": "/account/emp-career/point",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__emp-career__point' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/account/emp-career/point'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "hr",
            "icon": "team",
            "path": "/hr",
            "access": "normalRouteFilter",
            "routes": [
              {
                "name": "my-appraisal",
                "path": "/hr/my-appraisal",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hr__my-appraisal' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/hr/my-appraisal'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "appraisal-list",
                "path": "/hr/appraisal-list",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hr__appraisal-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/hr/appraisal-list'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "point-list",
                "path": "/hr/point-list",
                "access": "normalRouteFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hr__point-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/hr/point-list'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/idea-collector",
            "name": "idea-collector",
            "icon": "build",
            "routes": [
              {
                "name": "idea-list",
                "path": "/idea-collector/idea-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__idea-collector__idea-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/idea-collector/idea-list'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "task-list",
                "path": "/idea-collector/task-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__idea-collector__task-list' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/idea-collector/task-list'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/500",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__500' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/exception/500'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/403",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__403' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/exception/403'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/runner/work/riveroll-workbench/riveroll-workbench/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
