import request from '@/utils/request'
import { TableRequestSuccessResponse, CommonResponseData } from '@/utils/request'

import moment, { Moment } from 'moment'
import { DefaultDateFormat } from '@/utils/properties'
import { Yesterday } from '@/utils/Ranges';


export type IOperationType = 'add' | 'edit' | 'view'

export const FCAsyncRequestStatus = {
    Enqueued: 'Enqueued',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Running: 'Running',
    Stopped: 'Stopped',
    Stopping: 'Stopping',
    Invalid: 'Invalid',
    Expired: 'Expired',
    Retrying: 'Retrying'
}

// ### 智能监控 ###
// 获取游戏信息枚举
type IAppEnumsParams = {
  enumType: string;
}
export const getAppEnums = (
  params: IAppEnumsParams,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/common/app/enums', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 待处理条数
export const getUntreatedCount = (
  dt?: string,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/case/getUntreatedCount', {
    method: 'GET',
    params: {
      dt: dt || moment(Yesterday).format(DefaultDateFormat),
      noSpin: true
    },
    ...(options || {}),
  });
}

export const getAdjusterUntreatedCount = (
  dt?: string,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/case/getAdjusterUntreatedCount', {
    method: 'GET',
    params: {
      dt: dt || moment(Yesterday).format(DefaultDateFormat)
    },
    ...(options || {}),
  });
}

// 诊断策略名称列表
export const getDiagnosisListStrategyObj = (
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/diagnosis/strategy/listStrategyObj', {
    method: 'GET',
    ...(options || {}),
  });
}

export const getDiagnosisListStrategyCount = (
  dt?: string,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/case/getStrategyCount', {
    method: 'GET',
    params: {
      dt: dt || moment(Yesterday).format(DefaultDateFormat)
    },
    ...(options || {}),
  });
}


// ### 投放队列 ###
// 任务类型
export const taskTypeMap = {
  'create-campaign': 'Create Campaign',
  'create-ad-set': 'Create Ad Set',
  'create-creative-packs': 'Create Unity Creative Packs',
  'create-creative-packs-only': 'Add Unity Creative Packs',
  'create-mintegral-creatives': 'Add Mintegral Creative',
  'create-applovin-creatives': 'Add Applovin Creative',
  'create-adset': 'Create Adset',
  'create-ad': 'Create AD',
  'youtube_video': 'Add Youtube Video'
}
// 平台类型
export const platformTypeMap = {
  'applovin': 'Applovin',
  'mintegral': 'Mintegral',
  'unity': 'Unity',
  'tiktok': 'Tiktok',
  'youtube': 'Youtube',
}


// ### 素材库资源
type ILibraryVideosParams = {
  platformAccountId: string | number;
  advertiserId: string | number
}
export const getLibraryVideos = (
  params: ILibraryVideosParams,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/asserts/platformaccount/getLibraryVideos', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 同步素材
export const queryAyncTiktokMetadata = (
  params: ILibraryVideosParams,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/syncTiktokMetadata', {
    method: 'GET',
    params: {
      ...params,
      syncType: 'VIDEO_UPDATE'
    },
    ...(options || {}),
  });
}

// 获取试玩文件
export type ICloudListItem = {
  fileName?: string;
  isDir?: boolean;
  path?: string;
}
export type ICloudList = {
  code?: string | number;
  data?: ICloudListItem[];
  errorCode?: string;
  msg?: string;
}
type ICloudListParems = {
  dir: string;
}
export const queryCloudList = (
  params: ICloudListParems,
  options?: { [key: string]: any },
) => {
  return request<ICloudList>('/api/common/cloud/list', {
    method: 'GET',
    params: {
      ...params,
      noSpin: true
    },
    ...(options || {}),
  });
}


// ### 缓存
export type ICacheModule = {
  description?: string;
  moduleName?: string;
  title?: string;
  cacheNames?: string[]
}
export interface ICacheModuleList extends TableRequestSuccessResponse {
  data?: ICacheModule[];
}

// 获取缓存模块列表
export const getCacheModuleList = (
  options?: { [key: string]: any },
) => {
  return request<ICacheModuleList>('/api/common/getCacheModuleList', {
    method: 'GET',
    ...(options || {}),
  });
}

// 清除缓存
export const queryCleanCache = (
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/common/cleanCache', {
    method: 'GET',
    ...(options || {}),
  });
}

// 根据cacheName清除缓存
type ICleanCacheByNamesParams = {
  cacheNames: string | string[];
}
export const queryCleanCacheByNames = (
  params: ICleanCacheByNamesParams,
  options?: { [key: string]: any },
) => {
  return request<TableRequestSuccessResponse>('/api/common/cleanCacheByNames', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// ## 内部接口
// ### AIMIRROR
export const moreStyleSync = () => {
  return request<CommonResponseData>('/api/common/moreStyleSync', {
    method: 'POST'
  });
}

export const searchInfoSync = () => {
  return request<CommonResponseData>('/api/common/searchInfoSync', {
    method: 'POST'
  });
}

// ### SPELLAI
export const spellFeedSync = (env: string) => {
  return request<CommonResponseData>('/api/common/spellFeedSync', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const spellFeedRebuild = (env: string) => {
  return request<CommonResponseData>('/api/common/spellFeedRebuild', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const spellPromptRebuild = (env: string) => {
  return request<CommonResponseData>('/api/common/spellPromptRebuild', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const nsfwSync = () => {
  return request<CommonResponseData>('/api/common/nsfwSync', {
    method: 'POST'
  });
}
export const spellFeedSyncV2 = (env: string) => {
  return request<CommonResponseData>('/api/common/spellFeedSyncV2', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const spellFeedRebuildV2 = (env: string) => {
  return request<CommonResponseData>('/api/common/spellFeedRebuildV2', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const spellPromptRebuildV2 = (env: string) => {
  return request<CommonResponseData>('/api/common/spellPromptRebuildV2', {
    method: 'POST',
    params: {
        "env": env
    }
  });
}
export const nsfwSyncV2 = () => {
  return request<CommonResponseData>('/api/common/nsfwSyncV2', {
    method: 'POST'
  });
}

export const getAsyncFCStatus = (requestId: string) => {
  return request<CommonResponseData>('/api/common/fc/status', {
    method: 'GET',
    params: {
      "serviceName": "spellai-backend",
      "functionName": "test",
      "requestId": requestId,
      "noSpin": true
    }
  });
}

/** 环境分类 */
export type EnvCategory = {
  cname: string;
  env: string;
  id: string;
  name: string;
};