// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined';
import CrownOutlined from '@ant-design/icons/CrownOutlined';
import RocketOutlined from '@ant-design/icons/RocketOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import BuildOutlined from '@ant-design/icons/BuildOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import AccountBookOutlined from '@ant-design/icons/AccountBookOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined'

export default {
  SmileOutlined,
AreaChartOutlined,
CrownOutlined,
RocketOutlined,
ToolOutlined,
BuildOutlined,
SettingOutlined,
AccountBookOutlined,
UserOutlined,
TeamOutlined
}
    