// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined';
import CrownOutlined from '@ant-design/icons/es/icons/CrownOutlined';
import RocketOutlined from '@ant-design/icons/es/icons/RocketOutlined';
import ToolOutlined from '@ant-design/icons/es/icons/ToolOutlined';
import BuildOutlined from '@ant-design/icons/es/icons/BuildOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import AccountBookOutlined from '@ant-design/icons/es/icons/AccountBookOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import TeamOutlined from '@ant-design/icons/es/icons/TeamOutlined'
  export default {
    SmileOutlined,
AreaChartOutlined,
CrownOutlined,
RocketOutlined,
ToolOutlined,
BuildOutlined,
SettingOutlined,
AccountBookOutlined,
UserOutlined,
TeamOutlined
  }