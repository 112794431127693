/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  const roleCodeArray = currentUser && currentUser.roles.map( function( el ){ 
    return el.roleCode; 
   })
   const hasRoutes = currentUser && currentUser.hasRoutes;
  return {
    canAdmin: currentUser && currentUser.roles.map( function( el ){ 
      return el.roleCode;
     }).includes('admin'),
     normalRouteFilter: (route) => hasRoutes && hasRoutes.includes(route.path), 
  };
}
