// 公用常量
import { formatDayData, getDate, resetHost } from './utils';

export const IsDev = process.env.NODE_ENV === 'development';
export const TODAY: Date = new Date();
export const DefaultDateFormat = 'YYYY-MM-DD';
export const DefaultDateTimeFormat = 'YYYY-MM-DD hh:mm:ss';
export const Default24HDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const DefaultTimeFormat = 'hh:mm:ss';
export const DefaultDateNoTimeFormat = 'YYYY-MM-DD 00:00:00';
export const YESTERDAY: Date = getDate(-1);
export const LAST_WEEK: Date = getDate(-7);
export const LAST_TWO_WEEK: Date = getDate(-14);
export const LAST_MONTH: Date = getDate(-30);
export const LAST_YEAR: Date = getDate(-365);
export const TOMORROW: Date = getDate(1);
export const NEXT_WEEK: Date = getDate(7);
export const NEXT_TWO_WEEK: Date = getDate(7);
export const NEXT_MONTH: Date = getDate(30);
export const NEXT_TWO_MONTH: Date = getDate(60);

export const Today = formatDayData();
export const LastMonth = formatDayData(LAST_MONTH);
export const LastWeek = formatDayData(LAST_WEEK);
export const Yesterday = formatDayData(YESTERDAY);
export const NextMonth = formatDayData(NEXT_MONTH);

export const DefaultFormWrap = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 15 },
};

export interface IEditTypes {
  edit: string;
  create: string;
  select: string;
}

// export type IEditType = keyof IEditTypes;

export const EditTypes: IEditTypes = {
  edit: '编辑',
  create: '添加',
  select: '选择',
};

export const SexTypes = {
  1: '女',
  2: '男',
};

export enum SwitchValue {
  Inactive,
  Active,
}

export enum ICommonStat {
  Disabled,
  Enabled,
}

export const CommonStatus = {
  [ICommonStat.Disabled]: '禁用',
  [ICommonStat.Enabled]: '启用',
};

export const Operators = {
  GREATER_EQUALS: '大于等于',
  LESS_EQUALS: '小于等于',
  EQUALS: '等于',
  CONTAINS: '模糊匹配',
  LESS: '小于',
  GREATER: '大于',
  IN: '介于',
};
export const Weekdays = {
  Monday: {
    name: '星期一',
    day: 1,
  },
  Tuesday: {
    name: '星期二',
    day: 2,
  },
  Wednesday: {
    name: '星期三',
    day: 3,
  },
  Thursday: {
    name: '星期四',
    day: 4,
  },
  Friday: {
    name: '星期五',
    day: 5,
  },
  Saturday: {
    name: '星期六',
    day: 6,
  },
  Sunday: {
    name: '星期日',
    day: 7,
  },
};

export type IEmptyValueType = 'Symbol(empty)';
/**
 * 全局空值占位，当表单提交前将等于该值的数据清除
 * @author Carson
 * @date 2020/12/23
 * */
export const EMPTY_VALUE: IEmptyValueType = Symbol('empty').toString() as IEmptyValueType;

export type ICustomValueType = 'Symbol(custom)';
export const CUSTOM_VALUE: ICustomValueType = Symbol('custom').toString() as ICustomValueType;

export const SIZES = {
  small: 'small',
  default: 'default',
  large: 'large',
};

// @ts-ignore
export const DEFAULT_COMPONENT_SIZE: 'small' = SIZES.small;

/* 星期选择器 */
export type Weekday =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';
export type WeekdayData = {
  name: string;
  day: number;
};

/** 环境枚举变量名 */
export enum EnvName {
  /** 测试 */
  dev = 'DEV',
  /** 生产 */
  pro = 'PRO',
  /** 预发布 */
  pre = 'PRE',
}

export enum EnvId {
  /** 生产 */
  pro = '1',
  /** 预发布 */
  pre = '2',
  /** 测试 */
  dev = '3',
}

export const mapEnvIdToName = (id: string) => {
  switch (id) {
    case EnvId.pro:
      return EnvName.pro;
    case EnvId.dev:
      return EnvName.dev;
    case EnvId.pre:
      return EnvName.pre;
    default:
      return EnvName.dev;
  }
};
