import React from 'react';
import { MenuDataItem } from '@ant-design/pro-layout';
import * as allIcons from '@ant-design/icons';


// FIX从接口获取菜单时icon为string类型
const transformMenuItem = (menus: MenuDataItem[], subIndex=0, iconType='Outlined', collapsed=false): MenuDataItem[] => {
  if(!menus){
    return menus;
  }
  menus.forEach((item) => {
    const { icon, children, name } = item
    if (typeof icon === 'string') {
      let fixIconName = icon.slice(0,1).toLocaleUpperCase()+icon.slice(1) + iconType
      item.icon = React.createElement(allIcons[fixIconName] || allIcons[icon])
    }
    children && children.length>0 ? item.children = transformMenuItem(children, 1, 'Outlined', collapsed) : null
    if(children && children?.length > 1 && subIndex === 0) {
      if(!item['isAddTitle']) {
        item['isAddTitle'] = true
        children?.unshift({
          access: null,
          component: null,
          hideChildrenInMenu: true,
          icon: null,
          menuId: null,
          name: name,
          path: name,
          addTitle: true,
          disabled: true,
          hideInMenu: collapsed ? false : true
        })
      }else {
        if(children[0] && children[0].addTitle) {
          children[0].hideInMenu = collapsed ? false : true
        }
      }
    }
  });
  return menus
};

export default transformMenuItem;
